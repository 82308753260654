import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import ProductRegistration from '../components/ProductRegistration.vue';
import ProductRegistration02 from '../components/ProductRegistration02.vue';
import PurchaseHistory from '../components/PurchaseHistory.vue';
import AccumulatedPoint from '../components/AccumulatedPoint.vue'
import VerifyingProductPage from '../components/VerifyingProductPage.vue'

const routes = [
  { path: '/home', component: Home },
  { path: '/purchase-history', component: PurchaseHistory },
  { path: '/accumulated-point', component: AccumulatedPoint },
  { path: '/:qrCode?', component:  (process.env.VUE_APP_INTERFACE_MODE == 1)? ProductRegistration : ProductRegistration02},
  { path: '/verify-page', component: VerifyingProductPage},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
