<template>
    <div class="form-container">
        <div class="card">
            <div v-if="data" :class="{ valid_data: isValidData, invalid_data: !isValidData }">
                {{ data }}
            </div>
            <h1>Product Registration</h1>

            <div v-if="loading" class="spinner-container">
                <div class="spinner"></div>
            </div>
            <div v-if="loading">Verifying product, please wait ... </div>

            <form @submit.prevent="submitForm">
                <div>
                    <label for="phone_number">Phone Number:</label>
                    <input type="text" v-model="phone_number" id="phone_number" required />
                </div>
                <div>
                    <label for="serial_no">Serial Number:</label>
                    <input type="text" v-model="serial_no" id="serial_no" :readonly="true"/>
                </div>
                <div>
                    <label for="product">Product Name:</label>
                    <input type="text" v-model="product" id="product" :readonly="true"/>
                </div>
                <div>
                    <label for="lot">Product Lot:</label>
                    <input type="text" v-model="lot" id="lot" :readonly="true" />
                </div>
                <div>
                    <label for="manufacturer">Manufacturer:</label>
                    <input type="text" v-model="manufacturer" id="manufacturer" :readonly="true" />
                </div>
                <div>
                    <label for="expiry_date">Expired Date:</label>
                    <input type="text" v-model="expiry_date" id="expiry_date" :readonly="true" />
                </div>
                <button type="submit">Register</button>
                <button type="submit" @click="navigateTo('home', {})">Back to home</button>
            </form>
        </div>
        <CreateUserPopup 
            :visible="showCreateUserPopup" 
            :data="popup_data"
            @close="showCreateUserPopup = false" 
            @confirm="handleConfirm"
        >
            <h2>Popup Content</h2>
        </CreateUserPopup>
    </div>
</template>


<script>
import apiClient from '../services/api.js';
import CreateUserPopup from './CreateUserPopup.vue';

export default {
    name: 'ProductRegistration',
    components: {
        CreateUserPopup
    },
    data() {
        return {
            serial_no: null,
            phone_number: null,
            qrCode: null,
            product: null,
            lot: null,
            manufacturer: null,
            expiry_date: null,
            data: null,
            isValidData: false,
            showCreateUserPopup: false,
            popup_data: null,
            loading: false,
        };
    },
    async mounted() {
        this.qrCode = this.$route.params.qrCode || '';
        this.loading = true;
        try {
            const response = await apiClient.get('purchase', {
                params: {
                    qr_code: this.qrCode,
                }
            });   

            await this.delay(process.env.VUE_APP_DELAY_TIME);


            this.serial_no = response.data.serial_no;
            this.product = response.data.product;
            this.lot = response.data.lot;
            this.manufacturer = response.data.manufacturer;
            this.expiry_date = response.data.expiry_date;
            this.data = response.data.data;
            this.isValidData = true;
        }  catch(error){
            await this.delay(process.env.VUE_APP_DELAY_TIME);

            this.data = error.response.data.msg;
            this.isValidData = false;
        } finally {
            this.loading = false;
        }     
    },
    methods: {
        navigateTo(page, response_data) {
            const accumulated_point = response_data.product_credit_point;
            const total_accumulated_point = response_data.total_accumulated_point;
            this.$router.push({ 
                path: `/${page}`,
                query: { accumulated_point, total_accumulated_point }  
            });
        },
        submitForm() {
            apiClient.post('purchase', {
                qr_code: this.qrCode,
                phone_number: this.phone_number,
            })
            .then(response => {
                this.navigateTo('accumulated-point', response.data);
            })
            .catch(error =>{
                if (error.response){
                    if (error.response.data.no_phone_number_existed ){
                        this.showCreateUserPopup = true;
                        this.popup_data = error.response.data.msg;
                    }
                    else {
                        this.data = error.response.data.msg;
                        this.isValidData = false;
                    }
                }
            });
        },
        handleConfirm(confirmed){
            this.showCreateUserPopup = false;
            if (confirmed) {
                apiClient.post('customer', {
                    phone_number: this.phone_number,
                    product: this.product,
                }).then(response =>{
                    this.submitForm();
                }).catch(error =>{
                    this.data = error.response.data.msg;
                    this.isValidData = false;
                });
            }
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
};
</script>

<style scoped>
    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f5f5f5;
    }

    .card {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 300px;
    }

    .form-group {
      margin-bottom: 15px;
      text-align: left;
    }

    .invalid_data{
        color: red;
        font-weight: bold;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid red;
        background-color: #f8d7da;
        border-radius: 4px;
    }

    .valid_data{
        color: green;
        font-weight: bold;
        margin-top: 10px;
        padding: 5px;
        border: 1px solid green;
        background-color: #c5f2be;
        border-radius: 4px;
    }

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    form{
        button {
            display: block;
            width: 100%;
            padding: 10px;
            margin-top: 10px;
            border: none;
            border-radius: 4px;
            background-color: #007bff;
            color: white;
            font-size: 16px;
            cursor: pointer;
            }
    }

    button:hover {
      background-color: #0056b3;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner {
        border: 10px solid #f3f3f3; /* Light grey */
        border-top: 10px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 45px;
        height: 45px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
